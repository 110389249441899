import { Module, Action, getModule } from 'vuex-module-decorators';

import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import filterModel from './filter';
import NewsFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { TableApiInterface, TableInterface, TableTitle } from '@/lib/layouts/page/table.interface';
import { formatCreatedAt, truncateString } from '@/lib/utils/Utils';
import { getNewsList } from '@/admin/api/news';
import {
    FilterDateParamsInterface,
    FilterInputSuggestionsParamsInterface,
    FilterRadioParamsInterface,
    FilterSearchParamsInterface,
} from '@/lib/layouts/page/filterParams.interface';
import { getPainterList } from '@/admin/api/painter';
import { processListForSelect } from '@/lib/utils/form';
import { YesNoMap } from '@/constants';

export const MODULE_NAME = 'news';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ContentNews extends PageBaseModule {
    filter: Filter;

    constructor(module: ContentNews) {
        super(module);

        const page = new PageEntity();

        page.setTitle('Новости');
        page.setDefaultSortField('name');
        page.setSortName('NewsSort');

        const filter = new filterModel();
        this.filter = getModule(NewsFilter);
        this.filter.setTemplateClassName('template-lg');
        this.filter.setBtnClassName('row-5');
        this.filter.setFilterName('NewsFilter');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Action({ rawError: true })
    async init() {
        this.setDefaultSort();

        await this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        const sort = await this.getSortForRequest();

        this.SET_IS_LOADING(true);

        const table = await getNewsList(
            this.pageSettings.pageCurrent,
            sort,
            this.filter.filterSettings.filter,
            this.pageSettings.pageAmountItems,
        );

        this.SET_TABLE(await this.prepareTable(table));
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface) {
        const hiddenColumns = Object.values(table.titles as TableTitle[])
            .filter((title) => title.visible === false)
            .map((title) => title.id);

        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i == 'lead') {
                    cell = truncateString(cell, 60);
                }

                if (i == 'content') {
                    cell = truncateString(cell, 60);
                }

                if (i === 'showOnMain') {
                    cell = YesNoMap.get(cell);
                }

                if (i === 'image') {
                    cell = cell ? `<img src="${cell}" width="80" class="cp-input-radius-25" />` : '';
                }

                if (i === 'publishDate') {
                    cell = formatCreatedAt(cell);
                }

                if (i === 'createdByUser' && cell) {
                    cell = `<a href="${window.location.origin}/users/user/${cell.id}" target="_blank" class="cp-table__item-link">
                                <span class="cp-table-column__accent">${cell.fullName}</span>
                            </a>`;
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        return {
            titles: table.titles,
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateStartDate(params: FilterDateParamsInterface) {
        await this.filter.updateStartDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateEndDate(params: FilterDateParamsInterface) {
        await this.filter.updateEndDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateRadio(params: FilterRadioParamsInterface) {
        await this.filter.updateRadio(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    updateSuggestionsSelectedFilter(params: FilterInputSuggestionsParamsInterface) {
        params = { ...params, value: { id: params?.value?.id || '', value: params?.value?.value || '' } };
        this.filter.updateSuggestionsSelected(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSuggestionsSearchFilter(params: { key: string; value: string }) {
        const filter = params.value ? `&filters[0][id]=name&filters[0][value]=${params.value}` : '';
        this.filter.updateSuggestionsValue(params);

        const table = await getPainterList(1, this.pageSettings.filterRequestSort ?? '', filter, 50);
        const list = table ? Object.values(table.rows) : [];

        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(list) });
    }

    @Action({ rawError: true })
    reset() {
        this.RESET_TABLE();
    }
}
export default getModule(ContentNews);
