import { makeDeleteRequest, makeFormRequest, makeGetRequest } from '@/api/helpers';
import API from './../config/api-routers';

export const getNewsList = async (currentPage: number, sort: string, filter: string, items: string) => {
    const result = await makeGetRequest(`${API.news.getList}?page=${currentPage}${sort}${filter}&items=${items}`);

    return result.data.result.table;
};

export const getNewsCard = async (id: number) => {
    const result = await makeGetRequest(`${API.news.card}${id}/`);

    return result.data.result.item;
};

export const editNewsCard = async (id: number, data: any) => {
    const result = await makeFormRequest(`${API.news.card}${id}/`, data);

    return result.data.result.item;
};

export const createNewsCard = async (data: any) => {
    const result = await makeFormRequest(API.news.create, data);

    return result;
};

export const deleteNewsCard = async (id: number) => {
    const result = await makeDeleteRequest(`${API.news.card}${id}/`);

    return result;
};
