/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class NewsFilter {
    filterHandlers = {
        update: 'NewsFilter/updateFilter',
        reset: 'NewsFilter/resetFilter',
        resetState: 'NewsFilter/resetFilterState',
        removeSelected: 'NewsFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();

        filter.createSearchEntity('title');
        filter.setPlaceholder('title', 'По заголовку');
        filter.setName('title', 'search_title');
        filter.setPreviewName('title', 'Заголовок');
        filter.setIconClassName('title', 'icon-book');
        filter.setAction('title', 'news/updateSearch');
        filter.setClassName('title', 'col-1-row-1');

        filter.createDateIntervalPickerEntity('publishDate');
        filter.setName('publishDate', 'picker_date');
        filter.setTitle('publishDate', 'Время разм.');
        filter.setPreviewName('publishDate', 'Вр. разм.');
        filter.setDateIntervalList('publishDate', {
            startDate: {
                key: 'minPublishDate',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'news/updateStartDate',
            },
            endDate: {
                key: 'maxPublishDate',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'news/updateEndDate',
            },
        });
        filter.setClassName('publishDate', 'col-1-row-2');

        filter.createRadioEntity('showOnMain');
        filter.setTitle('showOnMain', 'Отображать на главной');
        filter.setName('showOnMain', 'radio_show_on_main');
        filter.setPreviewName('showOnMain', 'Показ');
        filter.setList('showOnMain', [
            { id: 0, name: 'Отображать', checked: false },
            { id: 1, name: '', checked: false },
            { id: 2, name: 'Не отображать', checked: false },
        ]);
        filter.setAction('showOnMain', 'news/updateRadio');
        filter.setClassName('showOnMain', 'col-2-row-2');

        filter.createSuggestionsEntity('owner');
        filter.setPlaceholder('owner', 'Начните вводить имя автора');
        filter.setName('owner', 'suggestions_owner');
        filter.setPreviewName('owner', 'Автор');
        filter.setAction('owner', 'news/updateSuggestionsSelectedFilter');
        filter.setSearchAction('owner', 'news/updateSuggestionsSearchFilter');
        filter.setIconClassName('owner', 'icon-pencil');
        filter.setClassName('owner', 'col-2-row-1');

        this.filterModel = filter.filterModel;
    }
}
