
import { Vue, Component } from 'vue-property-decorator';
import NewsModule from '@/admin/store/content/news';

import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TableBlock from '@/components/table/Table.vue';
import Loading from '@/components/Loading.vue';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';

@Component({
    components: {
        FlexLayout,
        AppHeaderMain,
        TableBlock,
        Loading,
    },
})
export default class NewsTableComponent extends Vue {
    get settings(): PageInterface {
        return NewsModule.pageSettings;
    }

    get filterSettings(): FilterSettings {
        return NewsModule.filter.filterSettings;
    }

    get isLoading(): boolean {
        return NewsModule.isLoading;
    }

    get paginationAction(): (number: string) => void {
        return NewsModule.updatePage;
    }

    get paginationItems(): { action: (value: string | number) => void; items: string } {
        return {
            action: NewsModule.updateItems,
            items: NewsModule.pageSettings.pageAmountItems,
        };
    }

    updateList(): void {
        NewsModule.getList();
    }

    async resetFilters(): Promise<void> {
        await NewsModule.clearSort();
        await NewsModule.filter.resetFilter();
        NewsModule.getList();
    }

    tableAction(params: { action: string; field: string; sort: string }): void {
        if (params.action === 'sort') {
            NewsModule.sort({ field: params.field, sort: params.sort });
        }
    }

    toAdd(): void {
        this.$router.push({ name: 'content_news_create' });
    }

    created(): void {
        NewsModule.init();
    }

    beforeDestroy(): void {
        NewsModule.reset();
    }
}
